<template>
    <div
        class="detail"
        v-if="info"
    >
        <div class="content-title">{{info.title}}</div>
        <div class="detail-wrap">
            <div class="detail-content">
                <div class="content-left">{{'项目领域' | language}}：</div>
                <div class="content-right">{{info.domain}}</div>
            </div>
            <div class="detail-content">
                <div class="content-left">{{'项目周期' | language}}：</div>
                <div class="content-right">{{info.duration}}</div>
            </div>
            <div class="detail-content">
                <div class="content-left">{{'面向人员背景' | language}}：</div>
                <div class="content-right">{{info.requirement}}</div>
            </div>
        </div>
        <div class="other-title">{{'内容介绍' | language}}</div>
        <div class="other-content">{{info.desc}}</div>
        <div class="other-title">{{'联系方式' | language}}</div>
        <div class="other-content">
            <div class="detail-content">
                <div class="content-left">{{'联系人' | language}}：</div>
                <div class="content-right">{{info.name}}</div>
            </div>
            <div class="detail-content">
                <div class="content-left">{{'邮箱' | language}}：</div>
                <div class="content-right">{{info.email}}</div>
            </div>
            <div class="detail-content">
                <div class="content-left">{{'电话' | language}}：</div>
                <div class="content-right">{{info.phone}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { downloadMinxin } from "../../mixin/download.js";
export default {
  mixins: [downloadMinxin],
  components: {},
  data() {
    return {
      id: "",
      info: null
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    showDialog() {
      this.$refs.add.edit(this.id);
    },
    async fetchData() {
      let res = await this.$http.get(`/information/research/${this.id}`);

      this.info = res;
    }
  }
};
</script>

<style lang="less" scoped>
.detail {
  text-align: left;
  min-height: 1000px;
  .content-title {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 32px;
  }
  .detail-wrap {
    margin-top: 20px;
    .detail-content {
      display: flex;
      font-size: 16px;
      line-height: 29px;
      .content-left {
        width: 115px;
        color: rgba(0, 0, 0, 0.6);
      }
      .cotent-right {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .other-title {
    margin-top: 40px;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.9);
    letter-spacing: 0;
    line-height: 32px;
  }
  .other-content {
    margin-top: 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 29px;
    .detail-content {
      display: flex;
      font-size: 16px;
      line-height: 29px;
      .content-left {
        width: 70px;
        color: rgba(0, 0, 0, 0.6);
      }
      .cotent-right {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .direction {
    display: flex;
  }
}
</style>